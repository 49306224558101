import React from "react"
import {
  Box,
  Text,
  Link,
  VStack,
  Container,
  SimpleGrid,
  Center,
  Image
  // useMediaQuery
} from "@chakra-ui/react"
import { FormattedMessage } from "gatsby-plugin-react-intl"
function Banner() {
  return (
    <Box
      minH="100vh"
      background="#04141f"
      bgImage="/top/bg.webp"
      bgPosition="bottom"
      bgRepeat="no-repeat"
      bgSize="contain"
      pt={{ base: '20px', md: "80px" }}
      px={4}
    >
      <SimpleGrid
        columns={[1, 1, 1, 2]}
        color="#fff"
        h="80%"
      >
        <Image src="/top/card.webp" />

        <Center p={4} >
          <VStack width={["100%", "90%", "70%"]}>
            <Text
              as="span"
              fontSize={["50px", "50px", "50px", "50px", "50px", "70px"]}
              color="#FF8008"
              fontWeight="bold"
              textAlign="center"
            >
              TRAVEL{" "}
              <Text as="span" color="#FFF">
                AND EARN
              </Text>
            </Text>
            <Text mt={2} textAlign="center">
              <FormattedMessage id="home.sub_title_1" />
            </Text>
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing={{ base: 4, xl: 2 }}
              pt={4}
            >
              <Container>
                <Link
                  href="https://apps.apple.com/us/app/xtrip-travel/id1637553605"
                  target="_blank"
                >
                  <Box
                    height="52px"
                    width="184px"
                    href="#"
                    bgImage="/download_2.png"
                    bgPosition="center"
                    bgSize="contain"
                    // onClick={() => onOpenModal()}
                    bgRepeat="no-repeat"
                  ></Box>
                </Link>
              </Container>
              <Container>
                <Link
                  href="https://play.google.com/store/apps/details?id=travel.xtrip.app"
                  target="_blank"
                >
                  <Box
                    height="52px"
                    width="184px"
                    href="#"
                    bgImage="/download_1.png"
                    bgPosition="center"
                    bgSize="contain"
                    // onClick={() => onOpenModal()}
                    bgRepeat="no-repeat"
                  ></Box>
                </Link>
              </Container>
            </SimpleGrid>
          </VStack>
        </Center>
      </SimpleGrid>
    </Box>

  )
}

export default Banner

