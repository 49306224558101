import React from "react"
import {
  Box,
  Text,
  Link,
  VStack,
  Container,
  SimpleGrid,
  Center,
  Heading,
  Image,
  Flex,
  AspectRatio,
  // useMediaQuery
} from "@chakra-ui/react"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import Layout from "../components/layout"
import { useInView } from 'react-intersection-observer';
import "./index.css"
import useLanguage from "../hooks/useLanguage"
import { VI } from "../intl/util"
import Travel from "../containers/top/travel";
import Banner from "../containers/top/banner";
import How from "../containers/top/how";
function App() {
  const { lang } = useLanguage()
  return (
    <>
      <Layout>
        <Box mt="80px" >
          <Banner />
          <Travel />
          <How />
          <Box minH="100vh" background="#132035" position="relative"
            px={[2, 8, 16, 24]}
            m={[8]}
            borderRadius="24px"
          >
            <VStack minH="100vh" p={4}>
              <Text color="#fff" textAlign="center" pt={16} pb={4} fontSize={{ base: '20px', md: '60px' }} fontWeight="bold">
                <FormattedMessage id="roadmap" />
              </Text>
              <Container flex={1} bgImage={{ base: `/top/roadmap/${lang}_mobile.webp`, xl: `/top/roadmap/${lang}_web.webp` }}
                maxW="unset"
                bgPosition=" center"
                bgRepeat="no-repeat"
                bgSize="contain"
              >
              </Container>
            </VStack>

          </Box>

          <AspectRatio ratio={16 / 9} m={[2, 8, 16, 24]} borderRadius="24px" zIndex={1} overflow="hidden" >
            <iframe
              src="https://www.youtube.com/embed/mW1kPd1Dmmw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </AspectRatio>
          <Box position="relative" pb={[8, 24]}>
            <Heading color="#fff" textAlign="center" py={16} fontSize="40px">
              <FormattedMessage id="xtrip_team" />
            </Heading>
            <SimpleGrid
              columns={[1, 2, 2, 3]}
              spacingX={[10, 10, 20, 20, 20, 40]}
              spacingY={10}
              px={[10, 10, 20, 20, 40, 60]}
            >
              {Array.from(Array(6), (e, i) => {
                return (
                  <VStack alignItems="center" color="#fff">
                    <Container flex={1}>
                      <Image
                        src={`/team_image_${i + 1}.png`}
                        bgSize="contain"
                        alt="roadmap"
                      />
                    </Container>

                    <Center></Center>
                    <Text fontWeight="bold" fontSize={24} textAlign="center">
                      <FormattedMessage id={`team_role_${i + 1}`} />
                    </Text>
                    <Text fontSize={24}>
                      <FormattedMessage id={`team_name_${i + 1}`} />
                    </Text>
                  </VStack>
                )
              })}
            </SimpleGrid>
          </Box>
        </Box>
      </Layout>
      {/* } */}
    </>
  )
}

export default App

export const Head = () => (
  <>
    <title>XTRIP | Web 3 travel App with Social-Fi and Game-Fi elements</title>
    <meta
      name="description"
      content="XTRIP is Web 3 travel app with Social-Fi and Game-Fi elements. User can earn crypto currency when user travel and share own experiences"
    />
    <meta name="agd-partner-manual-verification" />
  </>
)
