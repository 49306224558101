import React from "react"
import {
  Box,
  Text,
  Image,
  Center,
  Heading,
  Flex
} from "@chakra-ui/react"
import { FormattedMessage } from "gatsby-plugin-react-intl"


const data = [
  {
    bg: "linear-gradient(180deg, #285A3D 0%, #506EAA 32.81%, #1C645D 64.06%, #13326C 100%)"
  },
  {
    bg: "linear-gradient(180deg, #346A7B 0%, #2D2857 35.42%, #6659AA 61.7%, #140A44 103.97%)"
  },
  {
    bg: "linear-gradient(180deg, #973721 0%, #889E35 23.96%, #A16037 65.62%, #4F2104 96.87%)"
  }
]
function How() {
  return (
    <Box
      p={8}
      my={{ base: "4px", sm: "12px", md: 24, '2xl': 32 }}
      rounded="2xl"
      px={{ base: 4 }}

    >
      <Center >
        <Text fontSize={{ base: '20px', md: '60px' }}
          mb={{ base: '20px', md: '60px' }}
          color="#fff" textTransform="uppercase" fontWeight="800">
          <FormattedMessage id="how_it_works" />{" "}
        </Text>
      </Center>
      <Flex

        height="fit-content"
        gap={"30px"}
        flexWrap="wrap"
        justifyContent="center"
      >
        {data.map((item, index) => (
          <Box
            rounded="26px"
            p="24px"
            pb="80px"
            bgGradient={item.bg}
            color="#fff"
            maxW="350px"
          >
            <Center minH="155px" rounded="2xl" p={8} bgColor="#03141F">
              <Image src={`/top/how-${index}.webp`} h="140px" />
              {/* <Box
                height="100%"
                bgImage=
                bgPosition="center"
                bgRepeat="no-repeat"
                bgSize="contain"
              ></Box> */}
            </Center>
            <Heading as="h3" my={[2, 4, 6, 8]} f>
              <FormattedMessage id={`home.htw_title_${index + 1}`} />
            </Heading>
            <Text>
              <FormattedMessage id={`home.htw_subtitle_${index + 1}`} />
            </Text>
          </Box>
        ))}

        {/* <Box
          height={{ base: "100%", xl: "70%" }}
          rounded="26px"
          p={4}
          mt={{ base: "0%", xl: "60%" }}
          bgGradient="linear-gradient(180deg, #69E7EF 0%, #20AABD 109.68%)"
        >
          <Box height="40%" rounded="2xl" p={8} bgColor="#fff">
            <Box
              height="100%"
              bgImage="/icon_2.png"
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="contain"
            ></Box>
          </Box>
          <Heading as="h3" my={[2, 4, 6, 8]}>
            <FormattedMessage id="home.htw_title_2" />
          </Heading>
          <Text>
            <FormattedMessage id="home.htw_subtitle_2" />
          </Text>
        </Box>
        <Box
          height={{ base: "100%", xl: "70%" }}
          rounded="26px"
          p={4}
          mt={{ base: "0%", xl: "20%" }}
          bgGradient="linear-gradient(180deg, #FFACBB 0%, #8B7BF0 100%)"
        >
          <Box height="40%" rounded="2xl" p={8} bgColor="#fff">
            <Box
              height="100%"
              bgImage="/icon_3.png"
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="contain"
            ></Box>
          </Box>
          <Heading as="h3" my={[2, 4, 6, 8]}>
            <FormattedMessage id="home.htw_title_3" />
          </Heading>
          <Text>
            <FormattedMessage id="home.htw_subtitle_3" />
          </Text>
        </Box> */}
      </Flex>
    </Box>
  )
}

export default How
