import React from "react"
import {
  Box,
  Text,
  VStack,
  SimpleGrid,
  Center,
  Heading,
  Button,
} from "@chakra-ui/react"
import { FormattedMessage } from "gatsby-plugin-react-intl"


function Travel() {
  return (<SimpleGrid
    columns={[1, 1, 1, 2]}
    spacing={4}
    minH="100vh"
    // background="#04141f"
    pl={[2, 10, 20, 48]}
    p={8}
    gridAutoRows="1fr"
    color="#fff"
  >
    <Center height="100%">
      <VStack width="100%" alignItems="flex-start">
        <Box
          width="112px"
          height="8px"
          bgPosition="center"
          bgRepeat="no-repeat"
          bgImage="/line.png"
        />
        <Heading>
          <FormattedMessage id="home.title_2" />
        </Heading>
        <Text pt={4}>
          <FormattedMessage id="home.sub_title_2_1" />
          <br />
          <FormattedMessage id="home.sub_title_2_2" />
          <br />
          <FormattedMessage id="home.sub_title_2_3" />
          <br />
          <FormattedMessage id="home.sub_title_2_4" />
        </Text>
        <Text pt={4} pb={4}>
          <FormattedMessage id="home.sub_title_2_5" />
        </Text>
      </VStack>
    </Center>
    <Box
      // ref={downloadBgRef}
      // className={inViewDownloadBg ? "slide-top" : ""}
      height="100%"
      bgImage="/top/travel.webp"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="contain"
    ></Box>
  </SimpleGrid>


  )
}

export default Travel
